import type { AxiosError } from 'axios';
import { objectKeys } from '@utils/object';
import { camelcaseKeys } from '@utils/camelcaseKeys';
import { uuid } from '@utils/string/uuidGenerator';

import { HTTP_ERRORS_INFO, INTERNAL_ERROR_INFO } from './constants';

/**
 * первый тип валидационной ошибки, возвращаемой от бэка
 */
type ApiBEValidationError = {
  responseException: {
    exceptionMessage: string;
    validationErrors: {
      name: string;
      reason: string;
    }[];
  };
};

/**
 * второй альтернативный тип ошибки возвращаемой от бэка
 */
type AltApiBEValidationError = {
  responseException: {
    isValid: false;
    errors: [
      {
        propertyName: string;
        errorMessage: string;
        attemptedValue: string;
        severity: string;
        errorCode: string;
        formattedMessagePlaceholderValues: {
          propertyName: string;
          propertyValue: string;
        };
      },
    ];
    ruleSetsExecuted: string[];
  };
};

/**
 * третий, но не подтвержденный вариант тип ошибки
 */
export type ApiBeError = {
  responseException: {
    exceptionMessage: {
      errors: {
        errorMessage: string;
      }[];
    };
  };
};

export type ApiBEAspValidationError = {
  responseException: {
    exceptionMessage: {
      Errors: {
        [key: string]: string[];
      };
    };
  };
};

export type SimpleError = {
  message: string;
};

type OptimizedBeValidationError = {
  name: string;
  message: string;
};

const isBeValidationError = (body: unknown): body is ApiBEValidationError => {
  if ((body as ApiBEValidationError)?.responseException?.validationErrors) {
    return true;
  }

  return false;
};

const isBEAspValidationError = (
  body: unknown,
): body is ApiBEAspValidationError => {
  if (
    (body as ApiBEAspValidationError)?.responseException?.exceptionMessage
      ?.Errors
  ) {
    return true;
  }

  return false;
};

const isAltBeValidationError = (
  body: unknown,
): body is AltApiBEValidationError => {
  if ((body as AltApiBEValidationError)?.responseException.errors) {
    return true;
  }

  return false;
};

type SimpleNetworkError = {
  url?: string;
  body?: unknown;
  status: number;
};

export class ApiError extends Error {
  public name = 'ApiError';

  public id = uuid();

  constructor(
    public readonly url: string,
    public readonly status: number,
    public readonly body:
      | ApiBEValidationError
      | ApiBEAspValidationError
      | AltApiBEValidationError
      | unknown,
    message: string,
  ) {
    super(message);
  }

  static normalize(error: AxiosError | SimpleNetworkError | ApiError) {
    if (error instanceof ApiError) {
      return error;
    }

    // бэк присылает ошибки в разных ситуациях по разному, когда в кэмелКейсе, иногда в ПаскальКейсе
    // приводим все эти ошибки к единому виду кемелКейса
    const body = camelcaseKeys(
      ((error as AxiosError).response?.data ||
        (error as SimpleNetworkError).body ||
        {}) as Record<string, unknown>,
      { deep: true },
    );

    const status =
      (error as AxiosError).response?.status ||
      (error as SimpleNetworkError).status ||
      INTERNAL_ERROR_INFO.code;
    const message =
      this.getBEMessage(body) ||
      HTTP_ERRORS_INFO[status as keyof typeof HTTP_ERRORS_INFO].message;

    return new ApiError(
      (error as AxiosError).response?.config.url ||
        (error as SimpleNetworkError).url ||
        '',
      status,
      body,
      message,
    );
  }

  private static createErrorMessageFormList = (
    errorList?: Array<{ errorMessage: string }>,
  ) => {
    if (errorList) {
      return errorList.map(({ errorMessage }) => errorMessage).join(' \n');
    }

    return '';
  };

  public static getBEMessage = (data: unknown): string | undefined => {
    if (
      Array.isArray(
        (data as AltApiBEValidationError)?.responseException?.errors,
      )
    ) {
      return this.createErrorMessageFormList(
        (data as AltApiBEValidationError)?.responseException.errors,
      );
    }

    if (typeof (data as ApiBEValidationError)?.responseException === 'string') {
      return (data as ApiBEValidationError)
        ?.responseException as unknown as string;
    }

    if (
      typeof (data as ApiBEValidationError)?.responseException
        ?.exceptionMessage === 'string'
    ) {
      return (data as ApiBEValidationError)?.responseException
        ?.exceptionMessage;
    }

    if (
      Array.isArray(
        (data as ApiBeError)?.responseException?.exceptionMessage?.errors,
      )
    ) {
      return this.createErrorMessageFormList(
        (data as ApiBeError)?.responseException?.exceptionMessage?.errors,
      );
    }

    if (typeof (data as SimpleError)?.message === 'string') {
      return (data as SimpleError).message;
    }

    if (isBEAspValidationError(data)) {
      return objectKeys(data.responseException.exceptionMessage.Errors)
        .map((key) => data.responseException.exceptionMessage.Errors[key])
        .join(', ');
    }
  };

  public static getValidationErrors(
    error?: ApiError,
  ): OptimizedBeValidationError[] | undefined {
    if (!error) {
      return;
    }

    if (isBeValidationError(error?.body)) {
      return error?.body?.responseException?.validationErrors?.map(
        ({ name, reason }) => ({ name, message: reason }),
      );
    }

    if (isAltBeValidationError(error?.body)) {
      return error?.body?.responseException?.errors?.map(
        ({ propertyName, errorMessage }) => ({
          name: propertyName,
          message: errorMessage,
        }),
      );
    }

    if (isBEAspValidationError(error?.body)) {
      return objectKeys(
        error.body.responseException.exceptionMessage.Errors,
      ).map<OptimizedBeValidationError>((key) => ({
        name: key as string,
        message: (
          error.body as ApiBEAspValidationError
        ).responseException.exceptionMessage.Errors[key].join(', '),
      }));
    }
  }
}
