import {
  type EndpointParams,
  type InfiniteEndpointParams,
  type ReplaceRequestBody,
  type TypicalFilterParams,
} from '@shared';

import { tenantsManagementClient as client, identityClient } from '../clients';
import { cacheService } from '../../services';
import { type GetAffiliateListOrderBy } from '../../endpoints/tenantManagement';

export {
  type AffiliateDto,
  type AddAffiliateDto,
  type UpdateAffiliateDto,
  type SetAffiliateIntegrationStateDto,
  type GetAffiliateListOrderBy,
  type TenantDto,
} from '../../endpoints/tenantManagement';

export const affiliateFetcher = {
  list: cacheService.createInfiniteQuerySet(
    (params: TypicalFilterParams<GetAffiliateListOrderBy>) => ({
      execute: (infiniteParams) =>
        client.affiliates.affiliatesGetAffiliatesList({
          ...infiniteParams,
          ...params,
        }),
    }),
  ),
  tenants: cacheService.createInfiniteQuerySet(
    (
      params: InfiniteEndpointParams<
        typeof client.affiliates.affiliatesGetAffiliateTenantList
      >,
    ) => ({
      execute: (infiniteParams) =>
        client.affiliates.affiliatesGetAffiliateTenantList({
          ...infiniteParams,
          ...params,
        }),
    }),
  ),
  card: cacheService.createQuerySet((affiliateId: string) => ({
    execute: () =>
      client.affiliates.affiliatesGetAffiliateById({ affiliateId }),
  })),
  add: cacheService.createMutationSet(
    (
      params: ReplaceRequestBody<
        EndpointParams<typeof client.affiliates.affiliatesAddAffiliate>
      >,
    ) => client.affiliates.affiliatesAddAffiliate({ requestBody: params }),
  ),
  update: cacheService.createMutationSet(
    ({
      affiliateId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<typeof client.affiliates.affiliatesUpdateAffiliate>
    >) =>
      client.affiliates.affiliatesUpdateAffiliate({
        affiliateId,
        requestBody: params,
      }),
  ),
  toggleIntegration: cacheService.createMutationSet(
    ({
      affiliateId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<
        typeof client.affiliates.affiliatesSetAffiliateIntegrationState
      >
    >) =>
      client.affiliates.affiliatesSetAffiliateIntegrationState({
        affiliateId,
        requestBody: params,
      }),
  ),
  issueToken: cacheService.createQuerySet((temporaryToken: string) => ({
    execute: () =>
      identityClient.externalIntegrationTokens
        .externalIntegrationTokensGenerateIntegrationToken({
          temporaryToken,
        })
        .then(({ Token }) => Token),
  })),
};

export type AffiliateFetcher = typeof affiliateFetcher;
