import { Brand, createTheme } from '@astral/ui';
import UbuntuBoldWoff from '@astral/ui/fonts/UbuntuBold.woff';
import UbuntuBoldWoff2 from '@astral/ui/fonts/UbuntuBold.woff2';
import UbuntuLightWoff from '@astral/ui/fonts/UbuntuLight.woff';
import UbuntuLightWoff2 from '@astral/ui/fonts/UbuntuLight.woff2';
import UbuntuRegularWoff from '@astral/ui/fonts/UbuntuRegular.woff';
import UbuntuRegularWoff2 from '@astral/ui/fonts/UbuntuRegular.woff2';
import UbuntuMediumWoff from '@astral/ui/fonts/UbuntuMedium.woff';
import UbuntuMediumWoff2 from '@astral/ui/fonts/UbuntuMedium.woff2';

export type { Theme } from '@astral/ui';

const fontsUrls = {
  bold: { woff: UbuntuBoldWoff, woff2: UbuntuBoldWoff2 },
  light: { woff: UbuntuLightWoff, woff2: UbuntuLightWoff2 },
  regular: { woff: UbuntuRegularWoff, woff2: UbuntuRegularWoff2 },
  medium: { woff: UbuntuMediumWoff, woff2: UbuntuMediumWoff2 },
};

const kedoPalette = {
  primary: {
    900: '#5D3FD4',
    800: '#6746EB',
    700: '#8566FF',
    600: '#9075FF',
    500: '#9D85FF',
    400: '#B29EFF',
    300: '#C2B2FF',
    200: '#E0D9FF',
    100: '#EFEBFF',
  },
  secondary: {
    800: '#5653FF',
  },
  brand: {
    800: '#6746EB',
    background: '#FAFBFC',
  },
};

export type ChangeablePaletteColors = {
  PRIMARY900?: string;
  PRIMARY800?: string;
  PRIMARY700?: string;
  PRIMARY600?: string;
  PRIMARY500?: string;
  PRIMARY400?: string;
  PRIMARY300?: string;
  PRIMARY200?: string;
  PRIMARY100?: string;
  SECONDARY800?: string;
  BRAND800?: string;
  BACKGROUND?: string;
};

export const makeTheme = ({
  PRIMARY900 = kedoPalette.primary['900'],
  PRIMARY800 = kedoPalette.primary['800'],
  PRIMARY700 = kedoPalette.primary['700'],
  PRIMARY600 = kedoPalette.primary['600'],
  PRIMARY500 = kedoPalette.primary['500'],
  PRIMARY400 = kedoPalette.primary['400'],
  PRIMARY300 = kedoPalette.primary['300'],
  PRIMARY200 = kedoPalette.primary['200'],
  PRIMARY100 = kedoPalette.primary['100'],
  SECONDARY800 = kedoPalette.secondary['800'],
  BACKGROUND = kedoPalette.brand.background,
}: ChangeablePaletteColors) =>
  createTheme({
    nextFeatureFlags: { isUseNextTypography: true },
    brand: Brand.KEDO,
    fontsUrls,
    options: {
      palette: {
        primary: {
          '900': PRIMARY900,
          '800': PRIMARY800,
          '700': PRIMARY700,
          '600': PRIMARY600,
          '500': PRIMARY500,
          '400': PRIMARY400,
          '300': PRIMARY300,
          '200': PRIMARY200,
          '100': PRIMARY100,
          dark: PRIMARY800,
          main: PRIMARY800,
        },
        secondary: {
          '800': SECONDARY800,
        },
        // @ts-ignore
        brand: {
          '800': PRIMARY800,
          background: BACKGROUND,
        },
      },
    },
  });
