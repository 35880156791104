import { styled } from '../../styled';

export const Wrapper = styled('svg')<{ $isAsc: boolean; $isActive: boolean }>`
  --top: ${({ theme, $isActive, $isAsc }) =>
    $isActive && $isAsc
      ? theme.palette.primary.dark
      : theme.palette.primary.light};
  --bot: ${({ theme, $isActive, $isAsc }) =>
    $isActive && !$isAsc
      ? theme.palette.primary.dark
      : theme.palette.primary.light};
  width: 24px;
  height: 24px;
`;
