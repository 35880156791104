import { type PropsWithChildren, createContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouterSearchParams } from '@hooks/useRouterSearchParams';
import { routerService } from '@services/RouterService';
import {
  Filters,
  type FiltersOptions,
  type TypicalFilterParams,
} from '@stores/Filters';
import { useStore } from '@hooks/useStore';

type FilterContextWrapperProps<T extends TypicalFilterParams> = Pick<
  FiltersOptions<T>,
  'countableFilters' | 'initialFilters' | 'validator' | 'nonResetableFields'
>;

/**
 * @deprecated нужно переходить на работу без реакт контекста, фильтр должен создаваться фабрикой
 * */
export const FilterContext = createContext(
  new Filters({
    searchParams: new URLSearchParams(''),
    setSearchParams: () => {},
    initialFilters: {},
    validator: {},
  }) as Filters<{}>,
);

/**
 * @deprecated нужно переходить на работу без реакт контекста, фильтр должен создаваться фабрикой
 * */
export const InMemoryFilterContext = ({
  children,
  ...restProps
}: PropsWithChildren<FilterContextWrapperProps<{}>>) => {
  const filters = useMemo(
    () =>
      new Filters({
        searchParams: new URLSearchParams(''),
        setSearchParams: () => {},
        ...restProps,
      }) as Filters<{}>,
    [],
  );

  return (
    <FilterContext.Provider value={filters}>{children}</FilterContext.Provider>
  );
};

/**
 * @deprecated нужно переходить на работу без реакт контекста, фильтр должен создаваться фабрикой
 * */
export const FilterContextWrapper = observer(function FilterContextWrapper<
  T extends {},
>({ children, ...rest }: PropsWithChildren<FilterContextWrapperProps<T>>) {
  const searchParams = useRouterSearchParams();

  const filters = useStore(
    () =>
      new Filters<T>({
        searchParams: searchParams,
        setSearchParams: routerService.setSearchParams,
        ...rest,
      }),
  );

  return (
    <FilterContext.Provider value={filters}>{children}</FilterContext.Provider>
  );
});
