import { buildUTCDate } from '../buildUTCDate';

/**
 * Конвертация короткой записи строковой записи ГГГГ-ММ-ДД в дату с нулями по UTC
 */
export const toUTCByShortIso = (date: string): Date => {
  const parts = date.split('-').map((item) => parseInt(item));

  return buildUTCDate(parts[0], parts[1], parts[2]);
};
