import { TextField } from '../TextField';
import { styled } from '../styled';

export const Wrapper = styled(TextField, {
  shouldForwardProp: (prop) => !prop.match(/hideHelperText/),
})<{ $hideHelperText?: boolean }>`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 240px;
  }

  ${({ $hideHelperText }) =>
    $hideHelperText ? '.MuiFormHelperText-root' : ''} {
    display: none;
  }
`;
