import { type ApiError, HTTP_ERRORS } from '@services/http/ApiError';
import { notify } from '@components/Notification';
import { MSECONDS_PER_MINUTE } from '@services/DateService/constants';

const errorStatusExceptionList = [
  HTTP_ERRORS.UNAUTHORIZED_HTTP_CODE,
  HTTP_ERRORS.UNAVAILABLE_LEGAL_REASONS,
];

const notifications = new Set();

export const errorHandler = <T extends ApiError | Error | unknown>(
  error: T,
) => {
  if (errorStatusExceptionList.includes((error as ApiError).status) || !error) {
    return;
  }

  const id = (error as unknown as ApiError)?.id;

  // добавляем проверку на то, чтобы пользователю не показывались бы дублирующие друг друга нотификации
  // кейс заключается в том, что в доменной логике могут вызываться обработчики ошибки одного и того же запроса несколько раз
  // сложно решить, какой из этих обработчиков должен остаться, а какой следует убрать.
  // поэтому анализиуем id каждой ошибки, и если мы уже один раз показывали нотификашку для этой ошибки,
  // тогда игнорируем вызов, иначе запоминаем на минуту, и показываем
  if (id) {
    if (notifications.has(id)) {
      return;
    }

    setTimeout(() => notifications.delete(id), MSECONDS_PER_MINUTE);
    notifications.add(id);
  }

  notify.error((error as unknown as Error).message);
};
