/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TenantSettingsDto } from '../models/TenantSettingsDto';
import type { UpdateTenantSettingsDto } from '../models/UpdateTenantSettingsDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class TenantSettingsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Получение настроек тенанта по идентификатору
   * @returns TenantSettingsDto
   * @throws ApiError
   */
  public tenantSettingsGetTenantSettingsById({
    tenantId,
  }: {
    /** Идентификатор тенанта **/
    tenantId: string;
  }): CancelablePromise<TenantSettingsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/tenantmgmt/TenantSettings/{tenantId}',
      path: {
        tenantId: tenantId,
      },
    });
  }

  /**
   * Обновление настроек тенанта
   * @returns any
   * @throws ApiError
   */
  public tenantSettingsUpdateTenantSettings({
    tenantId,
    requestBody,
  }: {
    /** Идентификатор тенанта **/
    tenantId: string;
    /** Настройки тенанта **/
    requestBody: UpdateTenantSettingsDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/tenantmgmt/TenantSettings/{tenantId}',
      path: {
        tenantId: tenantId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
