import { cacheService } from '@shared';

import { templatesClient } from '../clients';

export const templatesFetcher = {
  data: cacheService.createQuerySet((templateId: string) => ({
    execute: () =>
      templatesClient.templates.templatesGetTemplate({
        templateId,
      }),
  })),
};

export type TemplatesFetcher = typeof templatesFetcher;
