import { isDate } from '@services/DateService/utils/isDate';

import { checkGuid } from '../../string';

export const checkTypeOnBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean';

export const checkTypeOnString = (value: unknown): value is string =>
  typeof value === 'string';

export const checkTypeWithSet = <TEnum>(
  value: unknown,
  set: Set<TEnum>,
): value is TEnum => set.has(value as TEnum);

export const checkTypeWithUnionArray = <
  TUnion,
  TValue extends TUnion | undefined | null = TUnion | undefined | null,
>(
  value: TValue,
  array: TUnion[],
) => Boolean(value) && array.includes(value as TUnion);

export const checkTypeOnNumber = (value: unknown): value is number =>
  typeof value === 'number';

export const checkTypeOnObject = (value: unknown): value is {} =>
  typeof value === 'object';

export const checkTypeOnArray = (value: unknown): value is unknown[] =>
  Array.isArray(value);

export const checkTypeOnGuidArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every(checkGuid);

export const checkTypeOnStringDate = (value: unknown): value is string =>
  checkTypeOnString(value) && isDate(new Date(value));

export const checkTypeOnStringArray = (value: unknown): value is string[] =>
  checkTypeOnArray(value) && value.every(checkTypeOnString);

export const checkTypeOnArrayWithSet = <TEnum>(
  value: unknown,
  set: Set<TEnum>,
): value is TEnum[] =>
  checkTypeOnArray(value) &&
  (value as TEnum[]).every((arrValue) => set.has(arrValue));
