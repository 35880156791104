import { createPath as path } from '@common/shared';

const AUTH = 'auth';
const LOGIN = 'login';
const DOCUMENT_TEMPLATES = 'document-templates';
const EMPLOYEES = 'employees';
const TENANTS = 'tenants';
const AFFILIATE = 'affiliate';

export const appPaths = {
  auth: {
    base: path(AUTH),
    login: path(LOGIN),
  },
  documentTemplates: {
    base: path(DOCUMENT_TEMPLATES),
    documentTemplates: path(DOCUMENT_TEMPLATES),
    manual: path(DOCUMENT_TEMPLATES, 'manual'),
    templates: path(DOCUMENT_TEMPLATES, 'templates'),
    tags: path(DOCUMENT_TEMPLATES, 'tags'),
    history: path(DOCUMENT_TEMPLATES, 'history'),
    system: path(DOCUMENT_TEMPLATES, 'system'),
  },
  employees: {
    base: path(EMPLOYEES),
  },
  affiliate: {
    base: path(AFFILIATE),
    card: (id: string) => path(AFFILIATE, id),
    tenants: (id: string) => path(AFFILIATE, id, 'created-tenants'),
  },
  tenants: {
    base: path(TENANTS),
    integrationTokens: (id: string) => path(TENANTS, id, 'integration-tokens'),
    tenantCard: (id: string) => path(TENANTS, id),
    tenantSettings: (id: string) => path(TENANTS, id, 'settings'),
    tenantEmployees: (id: string) => path(TENANTS, id, 'tenant-employees'),
  },
  styleguide: {
    base: path('styleguide'),
  },
  integrationTokens: {
    newToken: path('external-integration-tokens', 'new'),
  },
};
