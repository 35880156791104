import { Wrapper } from './styles';

type SortDirection = 'asc' | 'desc';

const MAP_OF_SORT: Record<SortDirection, SortDirection> = {
  asc: 'asc',
  desc: 'desc',
};

type SortIconProps = {
  sort?: SortDirection | boolean;
};

export const SortIcon = ({ sort, ...props }: SortIconProps) => (
  <Wrapper
    $isActive={Boolean(sort)}
    $isAsc={sort === MAP_OF_SORT.asc}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 16 16"
    {...props}
  >
    <path fill="var(--top)" d="m8 4 2.5 3h-5L8 4Z" />
    <path fill="var(--bot)" d="M8 12 5.5 9h5L8 12Z" />
  </Wrapper>
);
