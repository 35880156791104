import { ClientProvider } from '@common/data/api-core';

import { TenantManagementClient } from '../endpoints/tenantManagement';
import { SagasClient } from '../endpoints/sagas';
import { IdentityClient } from '../endpoints/identity';
import { TemplatesClient } from '../endpoints/templates';
import { type BaseApiUrlConfig } from '../../data/repositories/configFetcher';

import { ConfigFetcher } from './config';

export const tenantsManagementClient = new ClientProvider<
  TenantManagementClient,
  BaseApiUrlConfig
>({
  MicroserviceClient: TenantManagementClient,
  getBaseApiUrlQuery:
    ConfigFetcher.getInstance<BaseApiUrlConfig>().config.create,
}).client;

export const sagasClient = new ClientProvider({
  MicroserviceClient: SagasClient,
  getBaseApiUrlQuery:
    ConfigFetcher.getInstance<BaseApiUrlConfig>().config.create,
});

export const identityClient = new ClientProvider({
  MicroserviceClient: IdentityClient,
  getBaseApiUrlQuery:
    ConfigFetcher.getInstance<BaseApiUrlConfig>().config.create,
}).client;

export const templatesClient = new ClientProvider({
  MicroserviceClient: TemplatesClient,
  getBaseApiUrlQuery:
    ConfigFetcher.getInstance<BaseApiUrlConfig>().config.create,
}).client;
