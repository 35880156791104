import { memo, useMemo } from 'react';
import { uuid } from '@utils/string/uuidGenerator';

import { OverflowTypography } from '../OverflowTypography';
import { Tooltip } from '../Tooltip';
import { PeopleOutlineMd } from '../Icons';

import {
  AvatarGroupElement,
  AvatarGroupLink,
  AvatarGroupWrapper,
} from './styles';
import { MAP_STATUSES } from './constants';

const MAX_AVATARS_COUNT = 5;

type ItemProps = {
  name: string;
  jobTitle?: string | null;
  signed?: boolean;
  rejected?: boolean;
  src?: string | null;
  hidePersonalData?: boolean;
};

type AvatarGroupProps = {
  items: ItemProps[];
  link?: string;
  maxAvatarsCount?: number;
  hidePersonalData?: boolean;
};

type AvatarProps = ItemProps;

const getAvatarStatus = (signed?: boolean, rejected?: boolean) => {
  if (signed) {
    return MAP_STATUSES.signed;
  } else if (rejected) {
    return MAP_STATUSES.rejected;
  }
};

const TooltipTitle = ({ name, jobTitle, hidePersonalData }: ItemProps) => (
  <>
    <OverflowTypography hidePersonalData={hidePersonalData}>
      {name}
    </OverflowTypography>
    <OverflowTypography
      hidePersonalData={hidePersonalData}
      color="textSecondary"
      variant="pointer"
      component="div"
    >
      {jobTitle}
    </OverflowTypography>
  </>
);

const SingleAvatar = ({
  name,
  jobTitle,
  signed,
  rejected,
  src,
  hidePersonalData,
}: AvatarProps) => (
  <Tooltip
    title={
      <TooltipTitle
        name={name}
        jobTitle={jobTitle}
        hidePersonalData={hidePersonalData}
      />
    }
  >
    <AvatarGroupElement
      name={name}
      size="sm"
      status={getAvatarStatus(signed, rejected)}
      src={src || undefined}
    />
  </Tooltip>
);

export const AvatarGroup = memo(
  ({
    items,
    link,
    maxAvatarsCount = MAX_AVATARS_COUNT,
    hidePersonalData,
  }: AvatarGroupProps) => {
    const id = useMemo(() => uuid(), []);

    const slicedAvatars = items
      .slice(0, maxAvatarsCount)
      .sort(
        (a, b) =>
          Number(b.signed) - Number(a.signed) ||
          Number(b.rejected) - Number(a.rejected),
      );
    const isLinkVisible = items.length > maxAvatarsCount;
    const isAllSigned = items.every((item) => item.signed);
    const isAllRejected = items.every((item) => item.rejected);

    return (
      <AvatarGroupWrapper max={items.length}>
        {slicedAvatars.map((person, index) => (
          <SingleAvatar
            {...person}
            key={`${id}_${index}`}
            hidePersonalData={hidePersonalData}
          />
        ))}
        {isLinkVisible && link && (
          <Tooltip title="Посмотреть всех участников">
            <AvatarGroupElement
              name=""
              size="sm"
              status={getAvatarStatus(isAllSigned, isAllRejected)}
            >
              <AvatarGroupLink href={link}>
                <PeopleOutlineMd fill="white" />
              </AvatarGroupLink>
            </AvatarGroupElement>
          </Tooltip>
        )}
      </AvatarGroupWrapper>
    );
  },
);
