import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routerService } from '@services/RouterService';
import { useLocation } from '@hooks/useLocation';

export const RouterAdapter = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [params, setParams] = useSearchParams();

  useMemo(() => {
    routerService.setParams({
      navigate: (path, _params) =>
        typeof path === 'string'
          ? navigate({ pathname: path, ..._params }, _params)
          : navigate(path),
    });
  }, []);

  useMemo(() => {
    routerService.updateSearchParams(params, (data, _params) => {
      setParams(data, { replace: true, ..._params });
    });
  }, [params, setParams]);

  useMemo(() => {
    routerService.updatePathname(pathname);
  }, [pathname]);

  return null;
};
